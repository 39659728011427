/* .rs-dropdown-toggle,
.rs-dropdown-toggle.rs-btn {
  width: 200px !important;
} */

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 12px !important;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  border-radius: 12px !important;
  height: 42px !important;
}

.focused {
  border-color: #00aaff;
  /* Change this to the desired border color */
}

.error-message {
  font-size: 12px;
  color: #db2228;
}

.footerStyles {
  padding: 2px 10px;
  border-top: 1px solid #e5e5e5 !important;
}

.MainInput {}

.Autocomplete-cross {
  width: 10px;
  margin-left: -30px;
  margin-block: auto;
  cursor: pointer;
  background-color: white;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  height: 42px !important;
  padding-top: 10px;
}

.rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn-xs .rs-picker-toggle-clean {
  top: 7px !important;
}

/* .css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  -webkit-transform: rotate(0deg) !important;
  -moz-transform: rotate(0deg) !important;
  -ms-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
} */
th:first-child {
  position: relative !important;
}

/* .css-1iji0d4 {
  height: 40px !important;
} */
/* .css-1iji0d4.Mui-expanded {
  height: 40px !important;
} */
/* .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 40px !important;
  height: 40px !important;
} */
.rs-toggle-checked .rs-toggle-presentation {
  background-color: #db2228 !important;
}

.class-header {
  background-color: #db2228 !important;
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}

.rs-table-cell-header-icon-sort {
  color: white !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

.Btn {
  width: 50px !important;
  height: 50px;
  border: 2px solid rgb(214, 214, 214);
  border-radius: 15px;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition-duration: 0.3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.11);
}

.svgIcon {
  fill: rgb(70, 70, 70);
  font-weight: bold;
}

.icon2 {
  width: 18px;
  height: 5px;
  font-weight: bold;
  border-bottom: 2px solid rgb(70, 70, 70);
  border-left: 2px solid rgb(70, 70, 70);
  border-right: 2px solid rgb(70, 70, 70);
}

.Btn:hover {
  background-color: #db2228;
  transition-duration: 0.3s;
}

.Btn:hover .icon2 {
  border-bottom: 2px solid rgb(235, 235, 235);
  border-left: 2px solid rgb(235, 235, 235);
  border-right: 2px solid rgb(235, 235, 235);
  font-weight: bold;
}

.btn-span {
  font-weight: 500;
}

.Btn:hover .btn-span {
  color: white;
  font-weight: 600;
}

.Btn:hover .svgIcon {
  fill: rgb(255, 255, 255);
  animation: slide-in-top 1s linear infinite;
  font-weight: bold;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

/* .transition-all {
  transition-property: all !important;
  transition-duration: 0.5s !important;
  transition-timing-function: ease-in-out !important;
} */

/* Hide the tbody by default */
/* .hidden {
  display: none;
} */
.rc-collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.rc-collapse.show {
  height: auto;
}