/* Custom container for consistent spacing */
.custom-datepicker-container {
    width: 100%;
    max-width: 200px;
  }
  
  .custom-datepicker {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    padding: 10px 3px 10px 3px;
    font-size: 16px;
    color: #333;
    border: 1px solid #ccc; 
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    outline: none; 
  }

  .custom-datepicker:focus-within,
  .custom-datepicker:focus,
  .custom-datepicker input:focus {
    border: 1px solid #aaa !important;
    box-shadow: none !important; 
    outline: none !important;
  }
  
  .custom-calendar {
    background: white;
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border: none !important; 
  }
  
  .custom-calendar .react-calendar__tile--active {
    background: #d32f2f !important;
    color: white !important;
    border-radius: 8px;
  }
  
  .custom-calendar .react-calendar__tile {
    color: black;
    border-radius: 6px;
    padding: 8px;
  }
  
  /* Hover effect */
  .custom-calendar .react-calendar__tile:hover {
    background: rgba(211, 47, 47, 0.1);
    border-radius: 8px;
  }
  
  /* Error message styling */
  .error-message {
    color: #d32f2f;
    font-size: 12px;
    margin-top: 4px;
  }
  
  .react-date-picker__wrapper{
    border: thin !important;
  }

  .react-date-picker__inputGroup{
    font-size: smaller;
    height: 32px;
  }