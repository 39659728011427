.country-cell {
  color: blue; /* Text color */
  font-style: italic; /* Italic text */
  padding: 5px;
  border: 1px solid #ddd;
}

.rdt_TableHeadRow {
  background-color: #f0f0f0; /* Header row background */
}

.rdt_TableRow:hover {
  background-color: #f9f9f9; /* Row hover effect */
}

.bpcekY svg {
  display: none !important;
}
