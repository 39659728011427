.password-policy-container {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-color: #db2228;
}

.password-policy-container h3 {
  color: #333;
}

.password-policy-container ul {
  list-style-type: disc;
  padding-left: 20px;
}

.password-policy-container li {
  margin-bottom: 8px;
}

.password-policy-container li:last-child {
  margin-bottom: 0;
}
