/* .MuiAutocomplete-hasPopupIcon.css-udn6wi-MuiAutocomplete-root .MuiOutlinedInput-root{
    height: 30px !important;
}
.css-o1xa9-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-top: 0px !important;
    padding-bottom: 5px !important;
}
.css-wb57ya-MuiFormControl-root-MuiTextField-root{
    margin-top: 0.58rem !important;
} */

.css-1aufvzs-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 2px !important;
  border: none;
}

.css-1aufvzs-MuiAutocomplete-root .MuiAutocomplete-input:focus {
  box-shadow: none !important;
  border-color: #db2228 !important;
}

.css-1aufvzs-MuiAutocomplete-root .MuiAutocomplete-input:active {
  box-shadow: none !important;
  border-color: #db2228 !important;
}
.css-9e5uuu-MuiPaper-root-MuiAutocomplete-paper {
  min-width: 200px;
  border-color: #db2228 !important;
}
